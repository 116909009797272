<template>
  <router-view />
</template>

<script>
// import ComingSoon from "./views/ComingSoon.vue";

export default {
  name: "App",
  components: {
    // ComingSoon
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  height: 100vh;
  width: 100vw;
}
body {
  margin: 0;
}
</style>
