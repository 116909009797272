// import Vue from "vue";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

const app = createApp(App);
app.use(router);
axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}`;
app.config.globalProperties.$axios = axios;
app.mount("#app");