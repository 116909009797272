<template>
  <div class="add-friend-container">
    <img alt="Kowander logo" src="../assets/logo.png" class="logo" />
    <h1>Wander with Friends, Manage Expenses Together!</h1>
    <h2 class="details">
      Introducing Kowander, your go-to app for seamless trip planning and
      expense management. Set out on unforgettable adventures with your friends
      and keep a hassle-free track of shared expenses. Whether it's a
      spontaneous road trip or a well-planned getaway, make memories without the
      financial guesswork. Wander, explore, and Kowander with your crew today!
    </h2>
    <!-- <h1 class="download-now">Download Now</h1> -->
    <img
      alt="download with apple"
      src="../assets/app-store-badge.png"
      class="download-with-apple"
      :onclick="downloadApple"
    />
  </div>
</template>

<script>
export default {
    name: "AddFriend",
    methods: {
        downloadApple() {
            window.location.href = "https://apps.apple.com/app/6468917511";
    }
  }
};
</script>

<style scoped>
.add-friend-container {
  height: 100vh;
  width: 80%;
  text-align: center;
  margin: auto;
}

.logo {
  margin: 0;
  margin-top: 5vw;
  height: 20vw;
  max-height: 20vh;
}

h1 {
  font-size: 2em;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgb(86, 188, 108);
}


h2 {
  font-size: 1em;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgb(100, 100, 100);
}

.download-with-apple {
    cursor: pointer;
    margin-top: 1em;
    height: 3em;
}
</style>
