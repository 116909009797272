import { createRouter, createWebHistory } from "vue-router";
import ComingSoon from "@/views/ComingSoon.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import NotFound from "@/views/NotFound.vue";
import ContactUs from "@/views/ContactUs";
import TermsAndConditions from "@/views/TermsAndConditions.vue";
import AddFriend from "@/views/AddFriend.vue";

const routes = [
  {
    path: "/",
    component: ComingSoon
  },
  {
    path: "/privacypolicy",
    component: PrivacyPolicy
  },
  {
    path: "/termsandconditions",
    component: TermsAndConditions
  },
  {
    path: "/contactus",
    component: ContactUs
  },
  {
    path: "/add/:id",
    component: AddFriend
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound // Define this component to show your custom 404 page
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
